
import { readProductsList } from '@/store/products/getters';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})

export default class ProductsPagination extends Vue {

  public async handleChangePage(page) {
    this.$emit('change-page', page);
  }
  
  get productsResponse() {
    return readProductsList(this.$store);
  }
}
