
import { IProductsCategoryLight } from '@/interfaces/products';
import { readIsLoggedIn } from '@/store/main/getters';
import { dispatchToggleCategorySubscription } from '@/store/products/actions';
import { readCategoriesSubscriptions } from '@/store/products/getters';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class CategorySubscription extends Vue {
  @Prop({required: true}) public category!: IProductsCategoryLight;
  @Prop({required: false, default: 'm-b-120'}) public cardClass!: string;

  public async toggleCategorySubscription() {
    if (this.categoriesSubscriptions.includes(this.category.id)) {
      await dispatchToggleCategorySubscription(
        this.$store, {category_id: this.category.id, action: 'remove'},
      );
    } else {
      await dispatchToggleCategorySubscription(
        this.$store, {category_id: this.category.id, action: 'add'},
      );
    }
  }

  get categoriesSubscriptions() {
    return readCategoriesSubscriptions(this.$store);
  }

  get isLoggedIn() {
    return readIsLoggedIn(this.$store);
  }

}
