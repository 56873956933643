
import ProductGridCard from '@/components/Products/ProductGridCard.vue';
import ProductsPagination from '@/components/Products/ProductsPagination.vue';
import ProductListCard from '@/components/Products/ProductListCard.vue';
import { dispatchGetProductsFilters, dispatchGetProductsList } from '@/store/products/actions';
import { readProductsList } from '@/store/products/getters';
import { Component, Vue, Watch } from 'vue-property-decorator';
import CategorySubscription from '@/components/Products/CategorySubscription.vue';

@Component({
    components: {
        ProductGridCard,
        ProductListCard,
        ProductsPagination,
        CategorySubscription,
    },
})

export default class ProductsList extends Vue {
  public perPage = 10;
  public page = 1;
  public priceFilter: any = [null, null];
  public productListType = 'grid';
  public productSortType = '-rating';
  public loadingState: any = {
    list: false,
    page: true,
  };
  public filters: any = [];
  public selectedFilters: any = {};

  @Watch('$route.params')
  public onParamsChanged(toParams, previousParams) {
    console.log('params changed', toParams, previousParams);
    this.priceFilter = [null, null];
    this.selectedFilters = {};
    this.page = 1
    this.loadPageData();
  }

  public mounted() {
    let newObj = {};
    for (const [key, value] of Object.entries(this.$route.query)) {
      if (key === 'price_range') {
        // @ts-ignore
        let priceFilter = value.split(',');
        this.priceFilter = [Number(priceFilter[0]), Number(priceFilter[1])];
      } else if (key === 'page') {
        this.page = Number(value)
      } else if (value) {
        // @ts-ignore
        newObj[Number(key)] = value.split(',');
      }
    }
    this.selectedFilters = newObj;

    this.loadPageData();
  }

  public async loadPageData() {
    this.loadingState.list = true;
    this.filters = await dispatchGetProductsFilters(
      this.$store, {category: this.$router.currentRoute.params.codename},
    );
    this.handleChangePage(this.page, true);
    // const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;
    // let filters: any = [];
    // for (let i in this.selectedFilters) {
    //   if (this.selectedFilters[i].length > 0) {
    //     filters.push(this.selectedFilters[i]);
    //   }
    //   // filters = filters.concat(this.selectedFilters[i]);
    // }
    // await dispatchGetProductsList(
    //   this.$store, {data: {
    //     category: Number(this.$router.currentRoute.params.codename),
    //     filters,
    //     price_range: this.priceFilter,
    //     order_by: this.productSortType,
    //   }, rowQuery},
    // );
    this.loadingState.list = false;
    this.loadingState.page = false;
  }

  public async handleChangePriceRange() {
    await this.handleChangePage(1, true);
  }

  public async handleChangeFilters(filter, value) {
    let newObj = { ...this.selectedFilters };
    if (filter in newObj) {
      if (newObj[filter].includes(value)) {
        for( var i = 0; i < newObj[filter].length; i++){ 
          if ( newObj[filter][i] === value) { 
            newObj[filter].splice(i, 1); 
          }
        }
      } else {
        newObj[filter].push(value);
      }
    } else {
      newObj[filter] = [value];
    }
    this.selectedFilters = newObj;
    // console.log(this.selectedFilters);
    await this.handleChangePage(1, true);
  }

  public addFilterParamsToLocation() {
    let url = '?' +
      Object.keys(this.selectedFilters)
        .map(key => {
          return (
            encodeURIComponent(key) + '=' + encodeURIComponent(this.selectedFilters[key])
          );
        }).join('&');
    if (this.priceFilter[0] || this.priceFilter[1]) {
      if (url !== '?') {
        url += `&price_range=${encodeURIComponent(this.priceFilter)}`;
      } else {
        url += `price_range=${encodeURIComponent(this.priceFilter)}`;
      }
    }
    if (this.page > 1) {
      if (url !== '?') {
        url += `&page=${this.page}`
      } else {
        url += `page=${this.page}`
      }
    }
    if (url !== '?') {
      history.pushState(
        {}, '',
        `${this.$route.path}${url}`,
      );
    } else {
      history.pushState(
        {}, '',
        `${this.$route.path}`,
      );
    }
  }

  public async handleChangePage(page, force = false) {
    if (this.page != page || force) {
      this.loadingState.list = true;
      this.page = page;
      const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;
      let filters: any = [];
      for (let i in this.selectedFilters) {
        if (this.selectedFilters[i].length > 0) {
          filters.push(this.selectedFilters[i]);
        }
        // filters = filters.concat(this.selectedFilters[i]);
      }
      this.addFilterParamsToLocation();
      await dispatchGetProductsList(
        this.$store, {data: {
          category: this.$router.currentRoute.params.codename,
          filters,
          price_range: this.priceFilter,
          order_by: this.productSortType,
        }, rowQuery},
      );
      this.loadingState.list = false;
    }
  }
  
  public hadnleChangeOrdering(ordering) {
    if (this.productSortType !== ordering) {
      this.productSortType = ordering;
      this.handleChangePage(1, true);
    }
  }

  get sortTitle() {
    if (this.productSortType === '-rating') {
      return this.$t('По рейтингу');
    } else if (this.productSortType === '-created_at') {
      return this.$t('По дате');
    } else if (this.productSortType === 'price') {
      return this.$t('От дешевых к дорогим');
    } else if (this.productSortType === '-price') {
      return this.$t('От дорогих к дешевым');
    }
    return '';
  }

  get productsResponse() {
    return readProductsList(this.$store);
  }
}
